<script>
/*eslint-disable */
import {mapActions, mapGetters} from 'vuex';
import Axios from "axios";
import {VueContext} from 'vue-context';
import BlockPagination from '../../components/utility/block-pagination';

export default {
  name: 'TowDrivers',
  components: {
    VueContext,
    BlockPagination,
  },
  filters: {
    isActive(val) {
      if (val === true) {
        return 'Yes';
      }
      return 'No';
    },
    formatClaimType(val) {
      if (val == 'not_set') {
        return '';
      }
      return val;
    }
  },
  data: function () {
    return {
      towDrivers: [],
      towDriversFiltered: [],
      perPage: 50,
      currentPage: 1,
      filter: '',
      sortBy: 'number',
      sortDesc: true,
      allFields: [
        {label: "Company Name", key: "companyName", sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: "Email", key: "email", sortable: true, tdClass: 'clickable ', thClass: ''},
        {label: "Contact Nbr", key: "phone", sortable: true, tdClass: 'clickable number', thClass: ''},
        {label: "Location Nbrs", key: "locationsNumbers", sortable: true, tdClass: 'clickable ', thClass: ''},
        {label: "Operating Hrs", key: "locationsHours", sortable: true, tdClass: 'clickable ', thClass: ''},
        {label: "Active", key: "active", sortable: true, tdClass: 'clickable', thClass: ''},
        {label: "Date Created", key: "createdOn", sortable: false, tdClass: 'clickable ', thClass: ''},
        {label: "Actions", key: "actions", sortable: false, tdClass: 'clickable ', thClass: ''},
      ],
      contextOpenEvent: null,
    };
  },
  methods: {
    ...mapActions({
      setFilter: 'towDriver/setFilter',
    }),
    onFiltered(fi) {
      this.towDriversFiltered = fi;
    },
    tabChanged(selectedTab) {
      this.currentTab = selectedTab.tab.name;
    },
    clickAddTowDriver(){
      Axios.post('/ir/tow-drivers', {})
        .then(response => {
          if (response && response.data && response.data._status) {
            this.$router.push(`/tow-driver/${response.data.id}`);
            this.$toast.success('Tow Driver Created');
          }
        })
        .catch(e => console.error(e));
    },
    clickRepairerRow(data){
      this.$router.push(`/tow-driver/${data.id}`);
    },
    loadTowDrivers() {
      NProgress.start();
      Axios.get('/ir/tow-drivers')
          .then(response => {
            if (response && response.data && response.data._status) {
              this.towDrivers = response.data.data;
              this.towDriversFiltered = response.data.data;
            }
          })
          .catch(e => console.error(e))
          .finally(() => {
            NProgress.done();
          });
    },
    changeAllRepairersPage(){

    },
    deleteTowDriver(tow){
      if(!confirm(`Are you sure to delete ${tow.companyName}?`)) return;
      Axios.post(`/ir/tow-driver/${tow.id}/remove`, {towDriverId: tow.id})
          .then(response => {
            if (response && response.data && response.data._status) {
              this.$toast.success(`Tow driver company ${tow.companyName} has been removed`);
              this.loadTowDrivers();
            }
          })
          .catch(e => console.error(e));
    },
    contextOpen(event) {
      this.contextOpenEvent = event;
    },
    contextClose() {
      let event = this.contextOpenEvent;
      if (event) {
        let row = null;
        event.path.forEach(el => {
          if(row) return;
          if(el.nodeName == 'TR'){
            row = el;
          }
        })
        let rows = row.parentElement.rows;
        if(rows) for (let i = 0; i < rows.length; i++) {
          let elem = rows[i];
          elem.style.backgroundColor = "";
        }
      }
    },
    handleRightClick(item, index, e) {
      if (e) {
        e.preventDefault();
      }
      let row = null;
      e.path.forEach(el => {
        if(row) return;
        if(el.nodeName == 'TR'){
          row = el;
        }
      })
      let rows = row.parentElement.rows;
      if(rows) for (let i = 0; i < rows.length; i++) {
        let elem = rows[i];
        elem.style.backgroundColor = "";
      }
      row.style.backgroundColor = "#F3F3F3";
      this.contextClose();
      this.$refs['tow-drivers-context-menu'].close();
      this.$refs['tow-drivers-context-menu'].open(e, item, index);
    },
  },
  mounted() {
    if (!this.filter) this.filter = this.gettersFilter;
    if (this.gettersFilter) this.filter = this.gettersFilter;
    this.loadTowDrivers();
  },
  computed: {
    ...mapGetters({
      gettersFilter: 'towDriver/getFilter',
    }),
    computedTowDriversFiltered() {
      return this.towDriversFiltered;
    },
  },
  watch: {
    filter(val) {
      this.setFilter(val);
    },
  },
}
</script>

<template>
  <div class="claimlinq-page">
    <div class="page-header">
      <h4>Tow Drivers</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item">
          <router-link :to="'/dashboard'">Home</router-link>
        </li>
        <li class="breadcrumb-item active">Tow Drivers</li>
      </ol>
      <div class="page-header-nav-btn">
        <a @click="clickAddTowDriver" class="btn btn-primary btn-submit-quote">
          <i class='bx bx-plus'></i><span>Add Tow Driver</span>
        </a>
      </div>
    </div>
    <div class="claimlinq-body">
      <tabs ref="claimlinq-table" @changed="tabChanged" cache-lifetime="0" :options="{useUrlFragment:true}">
        <tab name="All" id="tow-drivers-all" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                responsive
                hover
                :items="towDrivers"
                :fields="allFields"
                :filter="filter"
                @filtered="onFiltered"
                :current-page="currentPage"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @row-clicked="clickRepairerRow"
                @row-contextmenu="handleRightClick"
                class="with-last-border"
            >
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{data.label}}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
              </template>

              <template v-slot:cell(locationsNumbers)="data">
                <div v-for="loc in data.item.locations">{{ loc.phone }}</div>
              </template>
              <template v-slot:cell(locationsHours)="data">
                <div v-for="loc in data.item.locations">{{ loc.operatingHours }}</div>
              </template>

              <template v-slot:cell(createdOn)="data">
                {{ data.item.createdOn | formatDatetime }}
              </template>

              <template v-slot:cell(active)="data">
                <span v-if="data.item.active">
                  <i class='bx bx-check-circle font-20 text-success'></i>
                </span>
                <span v-else>
                  <i class='bx bx-x-circle font-20 text-danger'></i>
                </span>
              </template>

              <template v-slot:cell(actions)="data">
                <a href="#" class="mr-0-5" @click.prevent="console.log('EDIT')" v-show="false">
                  <i class='bx bx-edit font-20 text-dropbox'></i>
                </a>
                <a href="#" @click.prevent="deleteTowDriver(data.item)">
                  <i class='bx bx-trash font-20 text-danger'></i>
                </a>

              </template>

            </b-table>
          </div>
          <block-pagination
              :role="'Tow Drivers'"
              :arrayOfSomeone="towDrivers"
              :arrayOfSomeoneFiltered="towDriversFiltered"
              :currentPage="currentPage"
              @changedCurrentPage="value => currentPage = value"
              :perPage="perPage"
              @changedPerPage="value => perPage = value"
          >
          </block-pagination>

        </tab>
        <template slot="nav-item-right">
          <div class="tabs-nav-right-search">
            <b-form-input v-model="filter" type="text" class="search-input" placeholder="Search or Filter results" debounce="100"></b-form-input>
          </div>
          <div class="tabs-nav-right-btn ml-4">
            <a @click="clickAddTowDriver" class="btn btn-primary btn-submit-quote">
              <i class='bx bx-plus'></i><span>Add Tow Driver</span>
            </a>
          </div>
        </template>
      </tabs>
    </div>

    <vue-context
        ref="tow-drivers-context-menu"
        class="context-menu-right-click list-group invoices-context-menu"
        :closeOnClick="true"
        :closeOnScroll="true"
        :subMenuOffset="100"
        @open="contextOpen"
        @close="contextClose"
    >
      <template slot-scope="item">
        <b-button-group ref="ctx-button-group" vertical class="d-flex" v-if="item && item.data">
          <b-button ref="ctx-button-1" class="text-left font-12 font-nunito--semibold" variant="light" block @click="deleteTowDriver(item.data)">Delete</b-button>
        </b-button-group>
      </template>
    </vue-context>

  </div>

</template>

<style>

.with-last-border .table tr:last-child td {
  border-bottom: 1px solid #dee2e6;
}

</style>
