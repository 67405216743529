<script>


import Axios from "axios";

export default {
  components: {
  },
  name: 'TowDriverViewContacts',
  props: {
    contacts: {
      type: Array,
      default: () => ([
        {
          location: '',
          levelUnitLot: '',
          streetName: '',
          suburb: '',
          state: '',
          postcode: '',
          country: 'AU',
        }
      ])
    },
    locations: {
      type: Array,
      default: () => ([
        {
          location: '',
          levelUnitLot: '',
          streetName: '',
          suburb: '',
          state: '',
          postcode: '',
          country: 'AU',
        }
      ])
    },
  },
  data(){
    return {
      allFields: [
        {label: "Name",     key: "name",       sortable: true, tdClass: 'number', thClass: ''},
        {label: "Position", key: "position",   sortable: true, tdClass: '',       thClass: ''},
        {label: "Phone",    key: "phone",      sortable: true, tdClass: 'number', thClass: ''},
        {label: "Email",    key: "email",      sortable: true, tdClass: '',       thClass: ''},
        {label: "Location", key: "locationId", sortable: true, tdClass: '',       thClass: ''},
        {label: "Action",   key: "action",     sortable: true, tdClass: '',       thClass: ''},
      ],
      filter: '',
    };
  },
  methods: {
    clickContactRow(){},
    getLocation(id){
      let f = {name:''};
      this.locations.forEach(l => {
        if(f.id) return;
        if(l.id == id){
          f = l;
        }
      })
      return f;
    },
    saveContact(item){
      NProgress.start();
      Axios.post(`/ir/tow-driver/${this.$route.params.towDriverId}`, {
        id: this.$route.params.towDriverId,
        contacts: [item]
      })
          .then(response => {
            if(response.data && response.data._status){
              this.$toast.success('Contact has been updated!');
              this.$emit('contact-updated');
            }
          })
          .catch(e => console.error(e))
          .finally(() => {
            NProgress.done();
          })
      item.edit = false;
    },
    removeContact(item){
      if(confirm(`Are you sure to remove ${item.name}?`)){
        NProgress.start();
        Axios.post(`/ir/tow-driver/${this.$route.params.towDriverId}/remove-contact`, {
          contactId: item.id
        })
            .then(response => {
              if(response.data && response.data._status){
                this.$toast.success('Contact has been removed!');
                this.$emit('contact-updated');
              }
            })
            .catch(e => console.error(e))
            .finally(() => {
              NProgress.done();
            })
      }
    },
  }
}
</script>

<template>

  <div class="row">
    <div class="col-md-12">
      <div class="card">

        <b-table
            responsive
            hover
            :items="contacts"
            :fields="allFields"
            :filter="filter"
            @row-clicked="clickContactRow"
        >
          <template v-slot:head()="data">
            <div v-if="!data.field.sortable">{{data.label}}</div>
            <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
          </template>

          <template v-slot:cell(name)="data">
            <span v-show="!data.item.edit">{{ data.item.name }}</span>
            <input v-show="data.item.edit" type="text" class="form-control" v-model="data.item.name">
          </template>

          <template v-slot:cell(position)="data">
            <span v-show="!data.item.edit">{{ data.item.position }}</span>
            <input v-show="data.item.edit" type="text" class="form-control" v-model="data.item.position">
          </template>

          <template v-slot:cell(phone)="data">
            <span v-show="!data.item.edit">{{ data.item.phone }}</span>
            <input v-show="data.item.edit" type="tel" class="form-control" v-model="data.item.phone">
          </template>

          <template v-slot:cell(email)="data">
            <span v-show="!data.item.edit">{{ data.item.email }}</span>
            <input v-show="data.item.edit" type="email" class="form-control" v-model="data.item.email">
          </template>

          <template v-slot:cell(locationId)="data">
            <span v-show="!data.item.edit">{{ getLocation(data.item.locationId).name }}</span>
            <select v-show="data.item.edit" v-model="data.item.locationId" class="form-control">
              <option v-for="loc in locations" v-bind:key="loc.id" :value="loc.id" :checked="loc.id == data.item.locationId">{{ loc.name }}</option>
            </select>
          </template>

          <template v-slot:cell(action)="data">
            <a href="#" class="mr-0-5" @click.prevent="data.item.edit = true" v-show="!data.item.edit">
              <i class='bx bx-pencil font-20 text-black'></i>
            </a>
            <a href="#" @click.prevent="removeContact(data.item)" v-show="!data.item.edit">
              <i class='bx bx-trash font-20 text-danger'></i>
            </a>
            <a href="#" class="mr-0-5" @click.prevent="saveContact(data.item)" v-show="data.item.edit">
              <i class='fa fa-save font-18 text-success' style="vertical-align: text-bottom;"></i>
            </a>
            <a href="#" v-show="data.item.edit" @click.prevent="data.item.edit = false">
              <i class='bx bx-x-circle font-20 text-danger'></i>
            </a>
          </template>
        </b-table>

      </div>
    </div>
  </div>


</template>
