<template>
  <div class="assessor-view-page">
    <div class="page-header">
      <h4>
        {{ pageTitle }}
        <span class="autosave">{{ autoSaveMessage }}</span>
      </h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link to="/dashboard">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/assessors">View Assessors</router-link>
        </li>
        <li class="breadcrumb-item active">
          <span class="d-none d-lg-inline-block">Assessor Details</span>
        </li>
      </ol>
    </div>
    <div class="claim-view-body">
      <tabs :options="{ useUrlFragment: false }" ref="assessor-view-tabs">
        <tab name="Details"
             id="assessor-view-details"
             :class-name="'tab-content-table'">

            <div class="row">
              <div class="col-md-8 pr-0">
                <div class="card">
                  <div class="card-header bg-navyblue">
                    <strong>Details</strong>
                  </div>
                  <div class="card-block bg-white col-md-6">

                    <div class="form-group row">
                      <label for="firstName" class="col-sm-4 col-4 col-form-label">First Name</label>
                      <div class="col-sm-8 col-8">
                        <input type="text" v-model="assessor.firstName" class="form-control" id="firstName" placeholder="First Name" readonly>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="lastName" class="col-sm-4 col-4 col-form-label">Surname</label>
                      <div class="col-sm-8 col-8">
                        <input type="text" v-model="assessor.lastName" class="form-control" id="lastName" placeholder="Surname" readonly>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="companyName" class="col-sm-4 col-4 col-form-label">Company Name</label>
                      <div class="col-sm-8 col-8">
                        <input type="text" v-model="assessor.companyName" class="form-control" id="companyName" placeholder="Business Name" readonly>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="contact" class="col-sm-4 col-4 col-form-label">Contact Nbr</label>
                      <div class="col-sm-8 col-8">
                        <input type="text" v-model="assessor.phoneNumber" class="form-control" id="contact" placeholder="Contact Nbr" readonly>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="email" class="col-sm-4 col-4 col-form-label">Email</label>
                      <div class="col-sm-8 col-8">
                        <input type="text" v-model="assessor.email" class="form-control" id="email" placeholder="Email address" readonly>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-4 pr-0">
                <div class="card">
                  <div class="card-header bg-navyblue">
                    <strong>Profile</strong>
                  </div>
                  <div class="card-block bg-white">

                    <div class="form-group row">
                      <label class="col-sm-4 col-4 col-form-label">Active</label>
                      <div class="col-sm-8 col-8">
                        <label class="form-check-inline radio">
                          <input v-model="assessor.active"
                                 class="form-check-input" type="radio"
                                 :value="true">
                          <span class="icon"><i class='bx bx-check'></i></span>
                          <span class="text">Yes</span>

                        </label>
                        <label class="form-check-inline radio">
                          <input v-model="assessor.active"
                                 class="form-check-input" type="radio"
                                 :value="false">
                          <span class="icon"><i class='bx bx-check'></i></span>
                          <span class="text">No</span>
                        </label>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="activeSince" class="col-sm-4 col-4 col-form-label">Active Since</label>
                      <div class="col-sm-8 col-8">
                        <input type="text" :value="assessor.updatedOn | formatDatetime" class="form-control" id="activeSince" placeholder="Active Since" readonly>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

        </tab>

        <template slot="nav-item-right">

          <div class="tabs-nav-right-options ml-4">
            <b-dropdown id="repairer-supplier-view-options" text="Options" class="dropdown-options">
              <template v-slot:button-content>
                Options
                <span class="dropdown-arrow">
                                    <i class='bx bxs-chevron-down'></i>
                                </span>
              </template>
              <b-dropdown-item v-show="!assessor.active" @click="assessor.active = true">Mark As Active</b-dropdown-item>
              <b-dropdown-item v-show="assessor.active" @click="assessor.active = false">Mark As Inactive</b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </tabs>
    </div>
  </div>
</template>

<script>
import Axios from "axios";

export default {
  name: 'AssessorView',
  data(){
    return {
      autoSaveMessage: null,
      assessor: {},
      assessorLoaded: false,
    };
  },
  watch: {
    'assessor.active': function(){
      if(this.assessorLoaded) {
        this.updateActiveStatus();
      }
    },
  },
  computed: {
    pageTitle(){
      return 'Assessor - ' + this.assessor.firstName + ' ' + this.assessor.lastName + ' - ' + this.assessor.companyName;
    },
  },
  mounted() {
    this.loadAssessor();
  },
  methods: {
    loadAssessor(){
      NProgress.start();
      Axios.get(`/ir/assessor/${this.$route.params.assessorId}`)
        .then(response => {
          if(response.data && response.data._status){
            this.assessor = response.data.data;
            setTimeout(() => this.assessorLoaded = true, 100);
          }
        })
        .catch(e => console.error(e))
        .finally(() => { NProgress.done() })
    },
    updateActiveStatus(){
      Axios.post(`/ir/assessor/${this.$route.params.assessorId}/set-active`, { active: this.assessor.active })
          .then(response => {
            if(response.data && response.data._status){
              this.$toast.success('Assessor status updated!');
              this.assessorLoaded = false;
              this.loadAssessor();
            }
          })
          .catch(e => console.error(e));
    },
    removeAssessor(){
      if(!confirm('Are you sure?')){
        return;
      }
      Axios.post(`/ir/assessor/${this.$route.params.assessorId}/remove`)
        .then(response => {
          if(response.data && response.data._status){
            this.$toast.success('Assessor removed successfully!')
            this.$router.push('/assessors');
          }
        })
        .catch(e => console.error(e));
    },
  },
};
</script>
